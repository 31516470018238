<template>
  <div>
    <!-- <v-row class="mb-8">
      <img
        src="../../../assets/images/Rectangle 1212 (8)-compressed.jpg"
        alt=""
        height="500"
        width="100%"
        :style="$vuetify.breakpoint.xs ? 'object-fit:cover' : ''"
      />
      <h2 class="absolute-h2">Case Study</h2>
    </v-row> -->

    <v-row class="mb-8">
      <div cols="12" style="overflow:hidden" class="container_">
        <img
          src="../../../assets/images/Rectangle 1212 (8)-compressed.jpg"
          alt=""
          height="500"
          width="100%"
          class="image"
          :class="$vuetify.breakpoint.xs ? 'top-img' : ''"
          :style="$vuetify.breakpoint.xs ? 'object-fit:cover' : ''"
        />
        <div class="overlay">
          <h2 class="absolute-h2">Case Study</h2>
        </div>
      </div>
    </v-row>

    <v-col cols="12" class="challenge-heading py-10">
      <v-spacer></v-spacer>
      <h2 style="color:#28A84A" class="text-center pb-8">
        Challenges with coldchain products
      </h2>
      <p
        :class="$vuetify.breakpoint.xs ? 'text-left' : 'text-center'"
        :style="
          $vuetify.breakpoint.xs
            ? 'width: 100%; margin-left: auto; margin-right: auto;'
            : 'width: 60%; margin-left: auto; margin-right: auto;'
        "
      >
        We discovered that each hand-off in the cold chain line opens the door
        for risk. Assumption of product integrity is not enough, without a
        reliable and consistent temperature monitoring and data logger,
        especially during storage, transit, and distribution to the final
        consumer.
      </p>
      <v-spacer></v-spacer>
    </v-col>
    <v-row
      class="py-16"
      style="background: rgba(230, 236, 238, 0.36); padding:3rem 6rem"
      :style="
        $vuetify.breakpoint.xs ? 'padding:3rem 1rem' : 'padding:3rem 6rem'
      "
    >
      <v-col cols="12" sm="6">
        <h2 class="pb-8">Methodology</h2>
        <p :style="$vuetify.breakpoint.smAndDown ? 'width:100%' : 'width:85%'">
          The Gricd Frij was powered on and set at temperature of 2 to 8 degrees
          celcius. Four (4) ampoules of Oxyotocin from a batch were placed in a
          cold room, another 4 ampoules from the same batch were placed in the
          GRICD FRIJ for 2 weeks. The samples were collected and analyzed using
          High Performance Liquid Chromatography (HPLC-PC) based on the internal
          SOP of Juhel pharmaceutical, and the results were thus:
        </p>
      </v-col>
      <v-col cols="12" sm="6">
        <v-carousel
          hide-delimiters
          height="300"
          width="300"
          cycle
          interval="4000"
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
            :src="item.src"
          ></v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    <div :class="$vuetify.breakpoint.xs ? 'py-4 pb-8' : 'py-16'">
      <v-spacer></v-spacer>
      <h2 class="text-center" style="color:#28A84A">
        Case Study Report
      </h2>
      <v-spacer></v-spacer>
    </div>
    <div
      :class="
        $vuetify.breakpoint.xs
          ? 'table-section pb-16'
          : 'table-section px-16 pb-16'
      "
    >
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left font-weight-bold">
                Average
              </th>
              <th class="text-left font-weight-bold">
                75IK01 cold room
              </th>
              <th class="text-left font-weight-bold">
                75IK01 from outside (CCE)
              </th>
              <th class="text-left font-weight-bold">
                Std (0.00106g)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style="background: rgba(230, 236, 238, 0.46);">
              <td></td>
              <td>3.0409</td>
              <td>3.103</td>
              <td>3.1091</td>
            </tr>
            <tr>
              <td></td>
              <td>3.1234</td>
              <td>3.0824</td>
              <td>3.011</td>
            </tr>
            <tr style="background: rgba(230, 236, 238, 0.46);">
              <td></td>
              <td>3.0960</td>
              <td>3.0927</td>
              <td>3.0601</td>
            </tr>
            <tr>
              <td class="font-weight-bold">
                IU
              </td>
              <td>5.470</td>
              <td>5.464</td>
              <td></td>
            </tr>
            <tr style="background: rgba(230, 236, 238, 0.46);">
              <td class="font-weight-bold">
                Assay
              </td>
              <td>109.4%</td>
              <td>109.3%</td>
              <td></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p class="body-2 text-center mt-8 font-weight-bold">
        Result of the HPLC comparative Assay of the cold room and CCE
      </p>
    </div>
  </div>
</template>

<script>
import one from '../../../assets/images/Rectangle 1222 (1)-compressed.jpg';
import two from '../../../assets/images/rectangle-1223_optimized-compressed.jpg';
import three from '../../../assets/images/Rectangle 1221 (1).png';

export default {
  name: 'Case3',
  data: () => ({
    items: [
      {
        src: one,
      },
      {
        src: two,
      },
      {
        src: three,
      },
    ],
  }),
  metaInfo() {
      return {
        script: [
          {
            type: 'application/ld+json',
            json: {
              '@context': 'http://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  name: 'Home',
                  item: 'https://gricd.com/',
                },
                {
                  '@type': 'ListItem',
                  position: 2,
                  name: 'Case Study',
                  item: 'https://gricd.com/about/case-studies',
                },
                {
                  '@type': 'ListItem',
                  position: 3,
                  name: 'Case Study: DrugStoc',
                  item: 'https://gricd.com/about/case-studies-1',
                },
              ],
            },
          },
        ],
      };
    },
};
</script>

<style lang="scss" scoped>
.circle {
  clip-path: circle(50% at 50% 50%);
  background: #b5ccbb;
  height: 28px;
  width: 28px;
  position: relative;
  right: 7px;
}
.square {
  width: 28px;
  height: 28px;
  background: #f1dbc1;
  transform: rotate(-42.61deg);
  position: relative;
  right: 7px;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 180%;
  color: #212529;
}
h4 {
  font-weight: bold;
  font-size: 20px;
  color: #212529;
  margin-bottom: 2rem;
}
.right-div {
  margin-bottom: 4.7rem;
}
.absolute-h2 {
  position: absolute;
  top: 14rem;
  left: 10rem;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  @media (max-width: 767px) {
    top: 11rem;
    left: 4rem;
    color: #28a84a;
  }
}
h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 1rem;
  color: #212529;
}

.container_ {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0.5rem;
  background: black;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  h2 {
    color: white;
  }
}

.container_ .overlay {
  opacity: 1;
}
.container_ h2 {
  border-bottom: solid 4px #28a84a;
}
</style>
